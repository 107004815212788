











































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class HostingPage extends Vue {
  currencies = { usd: "$", eud: "€" };

  subscriptions = {
    basic: {
      price: { usd: ["17", "00"], eud: ["14", "50"] },
      paypalURL: {
        usd:
          "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1XH072269V349063VMEBUGWA",
        eud:
          "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3BG87746PN999464PMEBUI3A",
      },
      specs: {
        cpu: "4 Core",
        memory: "8GB",
        storage: "200GB",
        network: "200Mb/s",
        managed: "Optional",
      },
    },
    standard: {
      price: { usd: ["25", "00"], eud: ["21", "50"] },
      paypalURL: {
        usd:
          "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5JX602365S030782XMAFANDA",
        eud:
          "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-48P27656PU516161JMAE62KI",
      },
      specs: {
        cpu: "6 Core",
        memory: "16GB",
        storage: "400GB",
        network: "400Mb/s",
        managed: "Optional",
      },
    },
    ultimate: {
      price: { usd: ["35", "00"], eud: ["30", "00"] },
      paypalURL: {
        usd:
          "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-42N885193H627043DMEBUMSI",
        eud:
          "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-83D05036XC795324NMEBULOY",
      },
      specs: {
        cpu: "8 Core",
        memory: "30GB",
        storage: "800GB",
        network: "600Mb/s",
        managed: "Optional",
      },
    },
  };

  changeCurrency() {
    /* eslint-disable */
    // @ts-ignore
    document.getElementsByClassName("symbol").forEach((el: HTMLSpanElement) => {
      /* eslint-enable */
      el.innerHTML =
        el.innerHTML === this.currencies.usd
          ? this.currencies.eud
          : this.currencies.usd;
    });

    document
      .getElementsByClassName("priceInt")
      /* eslint-disable */
      // @ts-ignore
      .forEach((el: HTMLSpanElement) => {
        /* eslint-enable */
        if (
          el.innerHTML === this.subscriptions.basic.price.usd[0] ||
          el.innerHTML === this.subscriptions.basic.price.eud[0]
        )
          el.innerHTML =
            el.innerHTML === this.subscriptions.basic.price.usd[0]
              ? this.subscriptions.basic.price.eud[0]
              : this.subscriptions.basic.price.usd[0];
        else if (
          el.innerHTML === this.subscriptions.standard.price.usd[0] ||
          el.innerHTML === this.subscriptions.standard.price.eud[0]
        )
          el.innerHTML =
            el.innerHTML === this.subscriptions.standard.price.usd[0]
              ? this.subscriptions.standard.price.eud[0]
              : this.subscriptions.standard.price.usd[0];
        else if (
          el.innerHTML === this.subscriptions.ultimate.price.usd[0] ||
          el.innerHTML === this.subscriptions.ultimate.price.eud[0]
        )
          el.innerHTML =
            el.innerHTML === this.subscriptions.ultimate.price.usd[0]
              ? this.subscriptions.ultimate.price.eud[0]
              : this.subscriptions.ultimate.price.usd[0];
      });

    document
      .getElementsByClassName("purchase")
      /* eslint-disable */
      // @ts-ignore
      .forEach((el: HTMLAnchorElement) => {
        /* eslint-enable */
        if (
          el.href === this.subscriptions.basic.paypalURL.usd ||
          el.href === this.subscriptions.basic.paypalURL.eud
        ) {
          el.href =
            el.href === this.subscriptions.basic.paypalURL.usd
              ? this.subscriptions.basic.paypalURL.eud
              : this.subscriptions.basic.paypalURL.usd;
        } else if (
          el.href === this.subscriptions.standard.paypalURL.usd ||
          el.href === this.subscriptions.standard.paypalURL.eud
        ) {
          el.href =
            el.href === this.subscriptions.standard.paypalURL.usd
              ? this.subscriptions.standard.paypalURL.eud
              : this.subscriptions.standard.paypalURL.usd;
        } else if (
          el.href === this.subscriptions.ultimate.paypalURL.usd ||
          el.href === this.subscriptions.ultimate.paypalURL.eud
        ) {
          el.href =
            el.href === this.subscriptions.ultimate.paypalURL.usd
              ? this.subscriptions.ultimate.paypalURL.eud
              : this.subscriptions.ultimate.paypalURL.usd;
        }
      });

    // The addition of a priceFloat loop would be required upon changing float prices per currency
  }
}
