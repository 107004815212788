<template>
  <div class="contact">
    <Nav />
    <HostingPage />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import HostingPage from "@/components/hostingPage.vue";
export default {
  name: "Hosting",
  components: {
    Nav,
    Footer,
    HostingPage
  },
  metaInfo: {
    title: "SeismicCore | Hosting",
    meta: [
      { name: "theme-color", content: "#57b3fd" },
      { property: "og:title", content: "SeismicCore | Hosting" },
      {
        property: "og:description",
        content: "Hosting plans and pricing."
      },

      {
        name: "description",
        content: "Hosting plans and pricing."
      },
      { name: "twitter:title", content: "SeismicCore | Hosting" },
      {
        name: "twitter:description",
        content: "Hosting plans and pricing."
      }
    ]
  }
};
</script>
<style>
@import "../assets/css/main.css";
</style>
